<template>
    <div>
        <navbar :items="navItems"/>
       <form action="" class="horizontal-form" @submit.prevent="addingUser">
           <div class="row">
               <div class="col form-group">
                   <label for="type_compte">Type de compte</label>
                   <select v-select="{placeholder: 'Selectionnez le type de compte'}" v-model="type_compte" name="type_compte" id="type_compte" class="form-control">
                       <option value=""/>
                       <option v-for="(type, index) in sortedTypeComptes" :value="type.uid" :key="index">{{type.libelle}}</option>
                   </select>
               </div>
           </div>
           <div class="row">
               <div class="col form-group">
                   <label for="fullname">Nom complet</label>
                   <input class="form-control" type="text" name="fullname" id="fullname" v-model="fullname">
               </div>
               <div class="col form-group">
                   <label for="login">Username</label>
                   <input class="form-control" type="text" name="login" id="login" v-model="login">
               </div>
           </div>
           <div class="row">
               <div class="col form-group">
                   <label for="pays">Pays</label>
                   <select v-select="{placeholder: 'Selectionnez le pays'}" v-model="selectedPays" name="pays" id="pays" class="form-control">
                       <option value=""/>
                       <option v-for="(p, index) in pays" :value="p.uid" :key="index">{{p.libelle}}</option>
                   </select>
               </div>
               <div class="col form-group">
                   <label for="telephone">Téléphone</label>
                   <input class="form-control" type="text" name="telephone" id="telephone" v-model="telephone">
               </div>
           </div>
           <div class="row">
               <div class="col form-group">
                   <label for="address">Adresse</label>
                   <textarea name="address" id="address"  rows="5" class="form-control"></textarea>
               </div>
           </div>
           <div class="row">
               <div class="col text-right">
                   <input type="submit" class="btn btn-primary" value="Ajouter">
               </div>
           </div>
       </form>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Navbar from '../../components/navBar.vue'
import {Select} from '../../directive/select'
import { REGISTRATION } from '../../graphql/user'
import { TYPE_ADMIN,  TYPE_PARTENAIRE, TYPE_SUPER_ADMIN } from '../../constants/app'
export default {
    directives: {Select},
    components: {Navbar},
    data(){
        return {
            navItems: [
                {name: 'Utilisateurs', route: 'users'},
                {name: 'Ajout'}
            ],
            type_compte: null,
            fullname: '',
            login: '',
            telephone: '',
            address: '',
            selectedPays: null
        }
    },
    methods: {
        addingUser(){
            this.$apollo.mutate({
                mutation: REGISTRATION,
                variables: {
					fullname: this.fullname,
                    login: this.login,
                    telephone: this.telephone,
                    typeCompte :this.type_compte,
                    pays: this.selectedPays
				},
                update: (store, { data  }) => {
                    console.log(data)
				},
            }).then((data) => {
                console.log(data)
                this.$router.push('users')
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            me: 'auth/me',
            typeComptes: 'auth/typeComptes',
            pays: 'auth/pays'
        }),
        AllowedCreatedType (){
            if(this.me !== null){
                let myTypeCompte = this.typeComptes.find(item => item.uid === this.me.typeCompte)
                switch(myTypeCompte.code){
                    case TYPE_SUPER_ADMIN: return [...this.typeComptes]
                    case TYPE_ADMIN: return [...this.typeComptes].filter(item => item.code === TYPE_PARTENAIRE)
                    case TYPE_PARTENAIRE: return [...this.typeComptes].filter(item => ![TYPE_SUPER_ADMIN, TYPE_ADMIN, TYPE_PARTENAIRE].includes(item.code))
                    default: return []
                }
            }
            return  []
        },
        sortedTypeComptes(){
            return [...this.AllowedCreatedType].sort((a,b) => a.libelle.localeCompare(b.libelle))
        }
    }
}
</script>

<style scoped>

</style>
